import { useState } from "react"

import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { 
    QueryText, 
    QueryFlipFlops, 
    QueryFloaters, 
    QueryBillboards, 
    QueryBillboardSliders, 
    QueryImpactFigures, 
    QueryVideos,
    QueryForm,
	QueryProfileCarousel,
	QueryImages,
    QuerySEO,
	QueryPartners
} from "../graphql/queries"

import _ from 'lodash'

import Head from 'next/head'
import Image from "next/image"

import handleSeomaticMeta from "../lib/seomatic"

import { HeaderShim } from "../fuselage/global/header/header"
import { AboveTheFold } from '../fuselage/theme/2024/layout/homepage/above-the-fold'
import { About } from '../fuselage/theme/2024/layout/homepage/about'
import { InNumbers } from '../fuselage/theme/2024/layout/homepage/in-numbers'
import { SpeakerCarousel } from '../fuselage/blocks/speaker-carousel/speaker-carousel'
import { SpeakerCard } from '../fuselage/components/speaker-card/speaker-card'
import { ArrowButton } from "../fuselage/components/arrow-button/arrow-button"
import { Quote } from "../fuselage/components/quote/quote"
import { ContentCard } from "../fuselage/components/content-card/content-card"
import { LogoCarousel } from "../fuselage/blocks/logo-carousel/logo-carousel"
import { MontageGrid, MontageImage, MontageSpacer, MontageBox, MontageGraphicTop, MontageGraphicBottom } from '../fuselage/theme/2024/layout/homepage/montage/montage'
import { OffsetCardGrid, SubGrid } from "../fuselage/layout/homepage/offset-cards/offset-cards"
import { QuoteGrid } from "../fuselage/theme/2024/layout/homepage/quote-grid/quote-grid"
import { VideoGrid } from "../fuselage/layout/homepage/video-grid/video-grid"
import { SponsorGrid } from '../fuselage/theme/2024/layout/homepage/sponsor-grid/sponsor-grid'
import { LogoBoxCarousel } from "../fuselage/components/logo-box-carousel/logo-box-carousel"
import { PopOver } from "../fuselage/components/popover/popover"
import { DelegateGuide } from "../fuselage/components/delegate-guide/delegate-guide"

import { GetBizzaboPartnersByLevelType, GetBizzaboPartnersByLevel } from "../lib/bizzabo-partners"


const FeatureCard = ({ iconUrl, heading, children}) => {
	return (
		<section className={`formatted`}>
			<p><Image src={iconUrl} width={100} height={100} alt="" /></p>
			<p className={`fs-4 fw-600`}>{ heading }</p>
			{ children }
		</section>
	)
}


export default function Home({ entry, videos, podcasts, bzSpeakers, sponsorsPage, mediaPage, airlines, speakers }) {


	const [ showDelegateGuide, setShowDelegateGuide ] = useState( false )



	// console.log('entry:', entry)
	// console.log('videos:', videos)
	// console.log('podcasts:', podcasts)
	// console.log('sponsorsPage:', sponsorsPage)
	// console.log('mediaPage:', mediaPage)
	// console.log('airlines:', airlines)
	// console.log('speakers:', speakers)



	// PARTNERS find the partnerGroup content-block
	const allPartners = sponsorsPage.aviadevContentBlocks.find( obj => obj.__typename === 'aviadevContentBlocks_partnerGroups_BlockType' )
	// console.log('partnerGroups:', allPartners.partnerGroups)
	
	// MEDIA PARTNERS find the partnerGroup content-block
	const mediaPartners = mediaPage.aviadevContentBlocks.find( obj => obj.__typename === 'aviadevContentBlocks_partnerGroups_BlockType' )
	// console.log('mediaPartnerGroups:', mediaPartners.partnerGroups)
	

	
	const sectionHeading = `fs-1 c-secondary lh-1 caps`
	const sectionHeadingInverse = `fs-1 white lh-1 caps`


	return (
		<>
			<Head>
				{ handleSeomaticMeta( entry.seomatic ) }
			</Head>

			<HeaderShim />


			<AboveTheFold>
                <div className="formatted">
                <p className='ff-outfit fs-lg fw-400 caps ls-2 lh-1' style={{ lineHeight: .95 }}>Transforming Connectivity Expanding Horizons</p>
                    <p className="fs-4 maxw-35 mt-md c-white">AviaDev is the premier platform dedicated to growing connectivity to, from, and within the African continent</p>
					<p className="mt-sm">
						<ArrowButton href={`https://www.eventbooking.uk.com/aviadev-2025`} solid theme={{ arrow: `white`, border: `var(--accent)`, label: `white`}} >REGISTER NOW</ArrowButton>
					</p>
                </div>
            </AboveTheFold>


			<About hero={`/assets/img/homepage/about-2024.jpg`}>
                <p className={`fs-1 lh-1 c-white caps`}>About AviaDev</p>
                <div className="fw-600 bg-dark formatted mt-sm">
                    <p>Bringing together airports, airlines, tourism authorities, governments and industry suppliers, AviaDev offers attendees the ability to pre-arrange meetings, learn from industry peers and make those important connections.</p>
                </div>
            </About>


            {/* overview + impact */}

            <InNumbers />


			{/* sponsor logos */}
			
			{
				allPartners.partnerGroups.length &&
				<SponsorGrid>
					{
						allPartners.partnerGroups.map( group => (
							<LogoBoxCarousel
								key={group.id}
								heading={group.title} 
								sponsors={group.partners} 
							/>
						))
					}
					{
						mediaPartners.partnerGroups.map( group => (
							<LogoBoxCarousel
								key={group.id}
								heading={group.title} 
								sponsors={group.partners} 
							/>
						))
					}
				</SponsorGrid>
			}


			{/* airlines */}

			<section className="block bg-wash">
				<div className="container">
					<LogoCarousel 
						sponsors={airlines.partners} 
						heading={'Airlines that attended AviaDev 2024'} 
					/>
				</div>
			</section>


			{/* speakers */}

			<SpeakerCarousel
				heading={`Speaker Highlights`}
				text={`Members of our speaking faculty are chosen for their industry knowledge, experience and the insight they offer to progress African aviation.`}
				ctaLink={`/speakers`}
				ctaLabel={`See all speakers`}
				decal={`bg-primary`}
			>
				{
					speakers &&
					speakers.map( speaker => {
						if ( speaker.hidden ) return
						return (
							<SpeakerCard
								key={speaker.id}
								url={`/speakers/profile/${speaker.slug}`}
								photoUrl={speaker.avatar.length ? speaker.avatar[0].url : null}
								fullName={speaker.title}
								jobTitle={speaker.positions[0].jobTitle}
								company={speaker.positions[0].organisation}
							/>
						)
					})
				}
				
			</SpeakerCarousel>


			{/* feature cards */}

			<div className="bg-primary">
				<OffsetCardGrid>

					<SubGrid background={`bg-dark`} offset={6}>
						<FeatureCard heading={`Airports / CAAs`} iconUrl={`/assets/img/homepage/icon-airports.svg`}>
							<p><strong>Airport/CAA delegates can choose to attend as a delegate with or without meetings.</strong></p>
							<p>Meet with multiple airlines to forge new relationships and grow capacity through new and existing routes. Talk with partner airports and discuss the performance of current and potential routes.</p>
						</FeatureCard>

						<FeatureCard heading={`Airlines`} iconUrl={`/assets/img/homepage/icon-airlines.svg`}>
							<p><strong>Airlines attend to take high-level route development meetings with new or current partner airports and airlines.</strong></p>
							<p>Develop your network, listen and gather educational insight via the conference programme.</p>
						</FeatureCard>
					</SubGrid>
					
					<SubGrid background={`bg-medium`}>
						<FeatureCard heading={`Suppliers`} iconUrl={`/assets/img/homepage/icon-suppliers.svg`}>
							<p><strong>Relevant industry suppliers can choose to attend with or without meetings.</strong></p>
							<p>Engage with potential partners in the African aviation community through our unique pre-arranged meeting system.</p>
						</FeatureCard>

						<FeatureCard heading={`Tourism Authorities`} iconUrl={`/assets/img/homepage/icon-tourism.svg`}>
							<p><strong>Tourism authorities are encouraged to attend and support their airports in their drive to attract and retain air services.</strong></p>
							<p>Support your airport and airline partners.  Connect with other destinations and gain insight through our varied conference programme.</p>
						</FeatureCard>
					</SubGrid>

				</OffsetCardGrid>
			</div>


			{/* quote */}

			<QuoteGrid>
				<div className="mt-md">
					<Quote 
						quote={`AviaDev Africa is a truly insightful event that allows for meaningful networking. It is an integral part of our route development initiatives.`} 
						name={`Cape Town Air Access`} 
						decal={`c-white`}
					/>
				</div>
			</QuoteGrid>


		

			{/* highlights video */}

			<VideoGrid videoUrl={`https://www.youtube.com/watch?v=-3hLIxi4tRw`}>
				<p className={sectionHeading}>AVIADEV 2024 VIDEO HIGHLIGHTS</p>
			</VideoGrid>


			{/* content-library videos */}

			<section className="block bg-default">
					
				<div className="container">
					<p className={sectionHeading}>Latest videos</p>
				</div>

				<div className="container columns-3 gap-md">

					{
						videos.map( video => {
							return (
								<ContentCard
									key={video.id}
									url={`/content-library/${video.slug}`}
									heroUrl={video.contentLibraryHero && video.contentLibraryHero[0] ? video.contentLibraryHero[0].url : null}
									heading={video.title}
									type={`video`}
								/>
							)
						})
					}
	
				</div>

				<div className="container">
					<p><ArrowButton href={`/content-library/category/videos-and-webinars`} theme={{ arrow: `var(--primary)`, border: `var(--primary)`, label: `var(--dark)`}} >MORE VIDEOS</ArrowButton></p>
				</div>
					
			</section>
			
			
			{/* content-library podcasts */}

			<section className="block bg-white bt-1 bc-light">
					
				<div className="container">
					<p className={sectionHeading}>Latest podcasts</p>
				</div>

				<div className="container columns-3 gap-md">
					{
						podcasts.map( podcast => {
							return (
								<ContentCard
									key={podcast.id}
									url={`/content-library/${podcast.slug}`}
									heroUrl={podcast.contentLibraryHero && podcast.contentLibraryHero[0] ? podcast.contentLibraryHero[0].url : null}
									heading={podcast.title}
									type={`podcast`}
								/>
							)
						})
					}
				</div>

				<div className="container">
					<p><ArrowButton href={`/content-library/category/podcast`}  theme={{ arrow: `var(--primary)`, border: `var(--primary)`, label: `var(--dark)`}} >MORE PODCASTS</ArrowButton></p>
				</div>
					
			</section>



			{/* quote */}

			<section className="block bt-1 bc-light">
				<div className="container">
					
					<div className="maxw-60">
						<Quote 
							quote={`An eye opener to industry players. Best forum to learn from others experience and forge ahead for the future in aviation.`} 
							name={`Kenya Airports Authority`} 
							decal={`c-primary`}
						/>
					</div>
					
				</div>
			</section>




			{/* photos */}

			<MontageGrid>

				<MontageBox span={2}>
					<div className="block">
						<p className={`${sectionHeading} c-white`}>AviaDev in photos</p>
					</div>
				</MontageBox>

				<MontageSpacer backgroundColor={`white`} span={2} />

				<MontageSpacer backgroundColor={`var(--bg-light)`} />
				
				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-01.jpg`} width={650} height={650}/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-02.jpg`} width={1250} height={890} span={2}/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-03.jpg`} width={1435} height={1050} span={2}/>
				
				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-04.jpg`} width={650} height={650}/>

				<MontageSpacer/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-05.jpg`} width={650} height={650}/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-06.jpg`} width={1435} height={1050} span={2}/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-07.jpg`} width={650} height={650}/>

				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-08.jpg`} width={1435} height={1050} span={2}/>
				
				<MontageImage  src={`/assets/img/homepage/gallery-2024/aviadev-09.jpg`} width={650} height={650}/>
				
				<MontageSpacer backgroundColor={`white`} span={2} />

				<MontageBox span={2}>
					<div className="block">
						<p>
							<ArrowButton 
								href={`https://www.flickr.com/photos/benchevents/albums/72177720318250719`} 
								target={`_blank`} 
								theme={{ arrow: `white`, border: `white`, label: `white`}}
							>See more photos</ArrowButton>
						</p>
					</div>
				</MontageBox>

			</MontageGrid>


			{/* quote */}

			<div className="block">
				<div className="container columns-2 gap-md">
					<div>
						<Quote 
							quote={`Wonderful connections and experience, have not missed a single edition from inception!`} 
							name={`Zambia Airports Corporation Limited`} 
							decal={`c-primary`}
						/>
					</div>
					<div>
						<Quote 
							quote={`A genuine, intimate and human conference, naturally bringing people together while creating and building on business relationships and opportunities.`} 
							name={`Protect Group`} 
							decal={`c-primary`}
						/>
					</div>
				</div>
			</div>


			<PopOver/>

			<DelegateGuide onClose={() => setShowDelegateGuide(false)} show={ showDelegateGuide } />

			
		</>
	)
}
Home.theme = 'aviadev-2024'





export async function getStaticProps({ preview, previewData }) {

    const data = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Homepage {
				entry(site: "${process.env.SITE_HANDLE}", id: "40870") {
					id
					title
					... on ${process.env.SITE_HANDLE}Homepage_${process.env.SITE_HANDLE}Homepage_Entry {
						${process.env.SITE_HANDLE}ContentBlocks {
							${QueryText}
                            ${QueryFlipFlops}
                            ${QueryFloaters}
                            ${QueryBillboards}
                            ${QueryBillboardSliders}
                            ${QueryImpactFigures}
                            ${QueryVideos}
                            ${QueryProfileCarousel}
                            ${QueryForm}
                            ${QueryImages}
						}
						${QuerySEO}
					}
				}
			}
        `
    })
    const pageData = data
    

	// sponsors from cms
	const sponsorData = await craftApolloClient().query({
        query: gql`
			query Page {
				entry(site: "${process.env.SITE_HANDLE}", id: "54163") {
					... on ${process.env.SITE_HANDLE}Partners_${process.env.SITE_HANDLE}Partners_Entry {
						id
						${process.env.SITE_HANDLE}ContentBlocks {
							${QueryPartners}
						}
					}
				}
			}
        `
    })

	// media partners from cms
	const mediaPartnerData = await craftApolloClient().query({
        query: gql`
			query Page {
				entry(site: "${process.env.SITE_HANDLE}", id: "54130") {
					... on ${process.env.SITE_HANDLE}Pages_page_Entry {
						id
						${process.env.SITE_HANDLE}ContentBlocks {
							${QueryPartners}
						}
					}
				}
			}
        `
    })
	
	// selected speakers from cms
	const speakersData = await craftApolloClient().query({
        query: gql`
			query Speakers {
				entries(site: "${process.env.SITE_HANDLE}", section: "profiles", id: [ 
					168140, 
					18363, 
					186864, 
					168099, 
					168113, 
					177699, 
					176802, 
					177749, 
					51046, 
					183637, 
					181991, 
					139129 
				]) {
					... on profiles_profile_Entry {
                        id
                        slug
                        title
                        avatar {
                            ... on avatars_Asset {
                                id
                                url
                                width
                                height
                            }
                        }
                        positions {
                            ... on positions_BlockType {
                                id
                                jobTitle
                                organisation
                            }
                        }
                        profile
                    }
				}
			}
        `
    })
	
	
	// airlines from cms
	const airlinesData = await craftApolloClient().query({
        query: gql`
			query PartnerGroupEntry {
				entry(site: "${process.env.SITE_HANDLE}", section: "partnerGroups", id: 155543) {
					... on partnerGroups_default_Entry {
						id
						partners {
							... on partners_partner_Entry {
								id
								title
								partnerLogo {
									url
									width
									height
								}
							}
						}
					}
				}
			}
        `
    })


	// content-library

	const videoQuery = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Videos {
				entries(site: "${process.env.SITE_HANDLE}", section: "contentLibrary", relatedTo: "7704", limit: 3) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
								slug
							}
						}
					}
				}
			}
        `
    })
    const videoData = videoQuery
    
	const podcastQuery = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Podcasts {
				entries(site: "${process.env.SITE_HANDLE}", section: "contentLibrary", relatedTo: "7701", limit: 3) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
								slug
							}
						}
					}
				}
			}
        `
    })
    const podcastData = podcastQuery


    return { 
		props: { 
			entry: pageData.data.entry,
			videos: videoData.data.entries,
			podcasts: podcastData.data.entries,
			sponsorsPage: sponsorData.data.entry,
			mediaPage: mediaPartnerData.data.entry,
			airlines: airlinesData.data.entry,
			speakers: speakersData.data.entries
		}
	}
}


