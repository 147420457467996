import Image from 'next/image'

import CSS from './quote-grid.module.css'

export const QuoteGrid = ({ children }) => {
    return (
        <section className={`${CSS.container} bg-primary`}>
            <div className={CSS.layout}>
                
                <div className={`${CSS.quoteContainer} block`}>
                    { children }
                </div>

                <div className={CSS.graphicContainer}>
                    <img className={CSS.desktop} src={`/assets/theme/2025/hero-swoosh.svg`} />
                    <img className={CSS.mobile} src={`/assets/theme/2025/hero-swoosh-mob.svg`} />
                </div>
            </div>
        </section>
    )
}