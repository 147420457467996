import { ImpactFigure } from "../../../../components/impact-figure/impact-figure"
import { Quote } from '../../../../components/quote/quote'

import CSS from './in-numbers.module.css'

export const InNumbers = () => {

    const sectionHeading = `fs-1 c-secondary lh-1 caps`
    
    return (
        <section className={CSS.layout}>
            {/* <div className={CSS.graphic}>
                
            </div> */}
            
            <div className={CSS.quote}>
                {/* <Quote 
                    avatar={{ url: `/assets/img/homepage/bisey-uirab.jpg`, alt: `Bisey Uirab`}}
                    quote={`The convergence of our expertise and the collective participation of aviation leaders will undoubtedly lead to impactful outcomes to improve air connectivity to, from and within Namibia and Africa`} 
                    name={`Bisey Uirab`} 
                    title={`Chief Executive Officer, Namibia Airports Company`}
                    decal={`c-accent`}
                /> */}
                <div className="mt-md">
                    <Quote 
                        avatar={{ url: `https://cms.thebench.com/assets/img/avatars/Girma-Wake.jpg`, alt: `Girma Wake`}}
                        quote={`It was an excellent and productive meeting. In two days we achieved what we normally don't achieve in a week`} 
                        name={`Ato Girma Wake`} 
                        title={`Industry Expert`}
                        decal={`c-primary`}
                    />
                </div>
            </div>
            
            <div className={CSS.impact}>
                <p className={sectionHeading}>AviaDev in numbers</p>
                <div className={CSS.impactGrid}>
                    <ImpactFigure figure={`500+`} caption={`Delegates`} />
                    <ImpactFigure figure={`36`} caption={`Airlines`} />
                    <ImpactFigure figure={`75%`} caption={`Attendees from Africa`} />
                    <ImpactFigure figure={`1200+`} caption={`Pre-arranged meetings delivered`} />
                    <ImpactFigure figure={`46`} caption={`Countries represented`} />
                </div>
            </div>
        </section>
    )
}