import Image from 'next/image'

import CSS from './sponsor-grid.module.css'

export const SponsorGrid = ({ children }) => {
    return (
        <section className={CSS.layout}>
            <div className={`${CSS.headingContainer} bg-primary`}>
                <div className={CSS.tab} />

                <div className={CSS.heading}>
                    <p className='fs-1 fw-600 lh-1 caps' style={{ position:`relative`, zIndex: 2 }}>2024 Sponsors</p>
                </div>
            </div>

            <div className={`${CSS.logos} bg-white flex gap-md jc-center flex-wrap`}>
                { children }
            </div>
        </section>
    )
}