import Image from 'next/image'
import Link from 'next/link'
         
import { IconButton } from '../icon-button/icon-button'

import CSS from './content-card.module.css'

export const ContentCard = ({ url, heroUrl, heading, excerpt, contentTypes, postDate, type }) => {

    // console.log('contentTypes:', contentTypes)

    const handleCategories = () => {
        if ( !contentTypes ) return
        return (
            <p className="fs-sm fw-600 c-secondary">
                { contentTypes.map( (type, index) => <span key={type.id}>{index > 0 ? <span className="fw-400 c-primary">&ensp;|&ensp;</span> : ''}{type.title}</span> ) }
            </p>
        )
    }

    return (
        <div className={CSS.layout}>

            <Link href={url}>
                <a className={CSS.hero}>
                    <span className={CSS.overlay}>
                        {/* <Image src={`/assets/theme/2023/content-library-card-overlay-2023.png`} layout='fill' alt="" /> */}
                        <Image src={`/assets/ui/content-library-card-overlay.png`} layout='fill' alt="" />
                    </span>
                    {
                        heroUrl ?
                        <Image src={ heroUrl } layout='fill' alt="" />
                        : 
                        <Image src={`/assets/ui/placeholder-card.png`} layout='fill' alt="" />
                    }
                </a>
            </Link>

            <div className={CSS.info}>
                {/* <div className={CSS.meta}>
                    {handleCategories()} 
                    <p className='fs-sm fw-600 c-primary'>Date</p>
                </div> */}

                <Link href={url}><a className={`${CSS.titleLink} fs-5 fw-500 c-medium lh-2 mt-xxs`}>{ heading }</a></Link>

                { 
                    excerpt &&
                    <p className='mt-xxs'>{ excerpt }</p>
                }

                <p className='caps fs-sm fw-500 mt-xs'>
                    <IconButton href={url} type={type} />
                </p>
            </div>

        </div>
    )
}