import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import { TileGrid, Tile, TileSpacer } from '../../theme/2023/layout/tile-grid/tile-grid'
import { ArrowButton } from "../../components/arrow-button/arrow-button"

import Chevron from './img/slider-chevron-right.svg'

import CSS from './speaker-carousel.module.css'

export const SpeakerCarousel = ({ children, heading, text, ctaLink, ctaLabel, decal }) => {

    return (

   


            <section className={`${CSS.layout} ${decal}`}>

                {/* <div className={CSS.graphics}>
                    <TileGrid>
                        <Tile src={`/assets/theme/2023/aviadev-2023-tile-02.png`} />
                        <Tile src={`/assets/theme/2023/aviadev-2023-tile-07.png`} />
                        <Tile src={`/assets/theme/2023/aviadev-2023-tile-09.png`} />
                    </TileGrid>
                </div> */}

           
                <div className={CSS.heading}>
                    <p className="fs-1 lh-1 caps">{ heading }</p>
                </div>
            

                <div className={CSS.slider}>

                    {/* sidebar */}
                    <div>
                        {
                            text && 
                            <div className='fs-5 formatted'>
                                { text }
                            </div>          
                        }

                        {
                            ctaLink && ctaLabel ?
                            <p className='mt-sm'>
                                <ArrowButton href={ctaLink} theme={{ border: `white` }}>{ ctaLabel }</ArrowButton>
                            </p>
                            : null
                        }
                    </div>
                
                    {/* main */}
                    
                    <Splide hasTrack={false} aria-label="Speakers" options={{
                        pagination: false,
                        gap: '2rem',
                        perPage: 4,
                        perMove: 1,
                        breakpoints: {
                            600: {
                                gap: '1rem',
                                perPage: 1
                            },
                            900: {
                                gap: '1rem',
                                perPage: 3
                            }
                        }
                    }}>
                        <SplideTrack>
                            { children.map( (item, index) => {
                                return (
                                    <SplideSlide key={index}>
                                        { item }
                                    </SplideSlide>
                                )
                            })}

                        </SplideTrack>
                        
                        <div className="splide__arrows">
                            <button className={`${CSS.sliderButton} ${CSS.prev} splide__arrow splide__arrow--prev`}>
                                <Chevron />
                            </button>
                            
                            <button className={`${CSS.sliderButton} ${CSS.next} splide__arrow splide__arrow--next`}>
                                <Chevron />
                            </button>
                        </div>
                    </Splide>
                    
                </div>

            </section>
     


    )
}