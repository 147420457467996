import { useState } from 'react'

import CSS from './delegate-guide.module.css'

export const DelegateGuide = ({ show, onClose }) => {

    const handleCloseClick = (e) => {
        e.preventDefault()
        onClose()
    }

    return (

        <div className={`${CSS.overlay} ${ show && CSS.active }`}>
            <button className={CSS.closeButton} onClick={handleCloseClick}>&times;</button>

            <div className={CSS.content}>
                <div style={{ position:`relative`, paddingTop:`max(60%,326px)`, height:`95vh`, width:`100%` }}>
                    <iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowFullScreen={true} style={{position:`absolute`, border:`none`, width:`100%`, height:`100%`, left:0, right:0, top:0, bottom:0 }} src="https://e.issuu.com/embed.html?d=240611_aviadev24_onsite_guide&u=aviadev">
                    </iframe>
                </div>
            </div>

        </div>

    )
}