import Image from 'next/image'

import CSS from './above-the-fold.module.css'

export function AboveTheFold ({ children, theme }) {
    return (
        <section className={`${CSS.container} ${ theme ? theme : `bg-primary`}`} >

            <div className={CSS.layout}>
                <div className={CSS.heroGraphic}>
                    <img className={CSS.desktop} src={`/assets/theme/2025/hero-swoosh.svg`} />
                    <img className={CSS.mobile} src={`/assets/theme/2025/hero-swoosh-mob.svg`} />
                </div>

                <section className={`${CSS.content} block`}>
                    { children }
                </section>
            </div>

            <div className={`${CSS.offset} ${ theme ? theme : `bg-primary`}`} ></div>

        </section>


    )
}