import Link from 'next/link'
import CSS from './icon-button.module.css'

import VideoSVG from './img/icon-video.svg'
import PodcastSVG from './img/icon-podcast.svg'

export const IconButton = ({ href, type, label }) => {

    const handleType = ( type ) => {
        if ( !type ) return

        switch ( type ) {
            case 'podcast' : return <PodcastSVG/>
                break

            case 'video' : return <VideoSVG/>
                break
            
            case 'videos-and-webinars' : return <VideoSVG/>
                break

            default : return <PodcastSVG/>
        }
    }

    const handleLabel = ( type ) => {

        switch ( type ) {
            case 'podcast' : return 'Listen'
                break

            case 'video' : return 'Watch'
                break

            case 'videos-and-webinars' : return 'Watch'
                break

            default: return 'Read more'
        }
    }


    return (
        <Link href={href}>
            <a className={CSS.button}>
                <span className={CSS.iconContainer}>{handleType(type)}</span> {handleLabel(type)}
            </a>
        </Link>
    )
}